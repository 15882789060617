<template>
    <page-view class="about-us" :uo-class="'about-us'">
        <!-- <div class="about-content-one">
            <div>
                <el-carousel style="height: 100% !important;" trigger="click" arrow="always" :interval="2000"
                    ref="carouselRef">
                    <el-carousel-item style="height: 100% !important;" v-for="(item, index) in bannerList" :key="index">
                        <img v-lazy="item.image" width="100%" height="100%" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div>
                <div>
                    <div style="font-weight: bold;letter-spacing: 4px;">成立</div>
                    <div>2017-2021</div>
                </div>
                <div>
                    <div style="font-weight: bold;letter-spacing: 4px;">发展</div>
                    <div>2022-2023</div>
                </div>
                <div>
                    <div style="font-weight: bold;letter-spacing: 4px;">提速</div>
                    <div>2024-2025</div>
                </div>
                <div>
                    <div style="font-weight: bold;letter-spacing: 4px;">飞跃</div>
                    <div>2026-2027</div>
                </div>
                <div>
                    <div style="font-weight: bold;letter-spacing: 4px;">创造</div>
                    <div>2028-..</div>
                </div>
                <div></div>
            </div>
        </div>

        <div class="about-content-two">
            <div class="content-two-1">
                <div>
                    <div>百川智慧商展到底是谁?</div>
                    <div>
                        百川智慧商展是由国内首家成立的商业展示行业组织一深圳市展示道具行业协会孵化的产业数字化平台，以协会平台为依托，汇聚国内外商业展示
                        产业链知名企业资源(国内优质的材料及道具制造资源，安装售后服务资源，以及20多个国家优质的、专业商业空间设计等经验的团队资源)。百川智慧商展坚持透明开放、
                        公平公正、 共建共享共生的发展理念，不断提高产业效率，为商业展示产业链上下游企业高质量可持续发展赋能，为品牌建店提质降本增效，降低社会成本，提高社会效益。
                    </div>
                </div>

                <div>
                    <img src="../assets/about/4.jpg" />
                </div>
            </div>

            <div class="content-two-2">
                <div>
                    <div>百川智慧商展能帮到您什么?</div>
                    <ul>
                        <li>利用协会及全产业的资源整合能力帮助品牌企业公平公正的优选供应链企业，保证产品品质可靠，确保产品性价比高，为品牌企业省时省钱放心，减少选择的烦恼！</li>
                        <li>专业的全面的角度为品牌企业定制建店系统解决方案，从建店设计优选、结构工艺专业优化、材料产品适配优选、全国区域制造企业优选、全国物流服务企业优选、全国安装服务优选、全国基装服务优选、全国售后服务优选到全产业链关键节点协同打通形成品牌建店高效运作闭环的系统解决方案。
                        </li>
                    </ul>
                </div>

                <div>
                    <img src="../assets/about/5.jpg" />
                </div>
            </div>

            <div class="content-two-3">
                <div>
                    <img src="../assets/about/6.jpg" />
                </div>
                <div>
                    <div>百川智慧商展为您创造什么价值?</div>
                    <div>
                        打通全产业链，提供系统解决方案,为品牌建店降低 10-20% 综合成本,
                        确保建店全流程公开透明，杜绝暗箱操作，让品牌建店可控、省心、放心。
                    </div>
                    <img src="../assets/about/17.png" />
                </div>
            </div>
        </div>

        <div class="about-content-three">
            <div class="content-three-1">
                <div>百川智慧商展核心服务是什么?</div>
                <div>商业展示产业第三方服务<br />提供品牌建店系统解决方案</div>
                <div>
                    <div>
                        <div>设计优选</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>材料优选</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>智造优选</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>物流优选</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>装潢优选</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>安装服务</div><img src="../assets/about/9.png" />
                    </div>
                    <div>
                        <div>售后服务</div><img src="../assets/about/9.png" />
                    </div>
                </div>
                <img src="../assets/img/455.png" />
            </div>

            <div class="content-three-2">
                <div>百川智慧商展专家顾问团队?</div>
                <div>
                    <div v-for="item in list" :key="item.name">
                        <div>
                            <img :src="item.img" />
                            <div>{{ item.name }}</div>
                        </div>
                        <div>
                            <div v-if="item.t1"> {{ item.t1 }}</div>
                            <div v-if="item.t2"> {{ item.t2 }}</div>
                            <div v-if="item.t3"> {{ item.t3 }}</div>
                            <div v-if="item.t4"> {{ item.t4 }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-three-3">
                <div>百川智慧商展企业文化是?</div>
                <div>
                    <div>
                        <div>宗旨</div>
                        <div>赋能品牌，共享生态</div>
                    </div>
                    <div>
                        <div>使命</div>
                        <div>创造一个美好的商业社会</div>
                    </div>
                    <div>
                        <div>目标</div>
                        <div>成为全球品牌最信赖的商展服务平台</div>
                    </div>
                    <div>
                        <div>愿景</div>
                        <div>成为一家幸福的企业，为全球合作伙伴创造长期价值</div>
                    </div>
                </div>
            </div>

        </div> -->
        <div>
            <img width="100%" src="../assets/000.jpg" />
            <img width="100%" src="../assets/001.jpg" />
            <img width="100%" src="../assets/002.jpg" />
            <img width="100%" src="../assets/003.jpg" />
            <img width="100%" src="../assets/004.jpg" />
        </div>
        <footer-com></footer-com>
    </page-view>
</template>
<script>
export default {
    name: "AboutUs",
    data() {
        return {
            list: [
                {
                    img: require("../assets/about/9.jpg"),
                    name: '释延豹',
                    t1: '原河南省政协委员',
                    t2: '中国国际文化传播中心爱心委员会副主席',
                    t3: '天安商会创会会长',
                    t4: '深圳天基权健康科技集团董事长兼总裁',
                },
                {
                    img: require("../assets/about/10.jpg"),
                    name: '骆冰',
                    t1: '法学硕士',
                    t2: '原深圳市民政局社会工作处、社区建设处处长',
                    t3: '深圳市第五届、第六届政协委员',
                },
                {
                    img: require("../assets/about/11.jpg"),
                    name: '崔云',
                    t1: '深圳市标识行业协会会长',
                    t2: '深圳市市场监督管理局标准院总工程师',
                },
                {
                    img: require("../assets/about/12.jpg"),
                    name: 'BERMAN',
                    t1: 'SHOP!零售营销广告协会大中华主席',
                },
                {
                    img: require("../assets/about/13.jpg"),
                    name: 'YAN',
                    t1: 'WOw...NUT (合桃品牌创新策略)创意总监兼合伙人SHOP!国际零售营销广告协会首席创新官',
                },
                {
                    img: require("../assets/about/14.jpg"),
                    name: 'MARGUERITE DECHATELPERRON',
                    t1: '(法国)WOW...NUT (合桃品牌创新策略)创新策略师',
                },
                {
                    img: require("../assets/about/15.jpg"),
                    name: '苏建东',
                    t1: '深圳市社会组织总会执行会长',
                },
                {
                    img: require("../assets/about/16.jpg"),
                    name: '张秋琳',
                    t1: '总经理',
                    t2: '慧聪行业公司商务部',
                    t3: '慧聪行业展示网',
                },
            ],
            bannerList: []
        }
    },
    created() {
        this.getImg()
    },
    methods: {
        async getImg() {
            let result = await this.$api.getBanner('PT00728');
            this.bannerList = result.itemList;
        },
    }
}
</script>
<style lang='scss' scoped>
.about-us {
    box-sizing: border-box;
    color: #353434;

    .about-content-one {
        color: white;

        >div:nth-child(1) {
            height: 36vw;

            :deep(.el-carousel__container) {
                height: 100% !important;
            }
        }

        >div:nth-child(2) {
            height: 11vw;
            display: flex;
            background-image: linear-gradient(to right, #41A350, #2C997C);
            font-size: 1vw;

            >div {
                flex: 1.8;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10%;
                border-right: 1px solid #FFFEFA;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    right: -2px;
                    top: 20%;
                    height: 20%;
                    background-color: white;
                    width: 3px;
                }
            }

            >div:last-child {
                flex: 0.7;
                border: none;

                &::after {
                    display: none;
                }
            }

            >div:nth-last-child(2) {
                border: none;

                &::after {
                    display: none;
                }
            }
        }
    }

    .about-content-two {

        .content-two-1,
        .content-two-2 {
            display: flex;
            justify-content: center;
            width: 90%;
            margin: auto;
            gap: 2%;

            >div:nth-child(1) {
                flex: 2;

                >div:nth-child(1) {
                    font-size: 3vw;
                }

                >div:nth-child(2),
                ul {
                    font-size: 1.3vw;
                    letter-spacing: 3px;
                    line-height: 2;

                    >li {
                        list-style-type: disc;

                        &::marker {
                            color: white;
                        }
                    }
                }
            }

            >div:nth-child(2) {
                height: 100%;
                flex: 1;

                >img {
                    aspect-ratio: 4/3.5;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .content-two-1 {
            margin: 6% auto;
            gap: 8%;

            >div:nth-child(1) {
                >div:nth-child(1) {
                    padding-bottom: 2%;
                    margin-bottom: 2%;
                    position: relative;
                    display: inline-block;
                    background-image: linear-gradient(to right, #0D94A3, #47993F);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &::after {
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        content: '';
                        width: 30%;
                        height: 4px;
                        background-color: #429C48;
                    }
                }
            }
        }

        .content-two-2 {
            width: 100%;
            color: white;
            padding: 0% 5%;
            box-sizing: border-box;
            background-image: linear-gradient(to right, #41A350, #2C997C);

            >div:nth-child(1) {
                margin: 2% 0%;

                >ul>li {
                    margin-top: 4%;
                }
            }

            >div:nth-child(2) {
                text-align: end;

                >img {
                    width: 80%;
                    aspect-ratio: 2/2.7;
                }
            }
        }

        .content-two-3 {
            display: flex;
            justify-content: center;
            width: 90%;
            margin: 6% auto;
            gap: 5%;

            >div:nth-child(1) {
                flex: 2;

                >img {
                    aspect-ratio: 3.5/2;
                    width: 100%;
                }
            }

            >div:nth-child(2) {
                flex: 3;
                position: relative;

                >div:nth-child(1) {
                    font-size: 3vw;
                    padding-bottom: 3%;
                    margin-bottom: 3%;
                    position: relative;
                    display: inline-block;
                    background-image: linear-gradient(to right, #0D94A3, #47993F);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &::after {
                        position: absolute;
                        bottom: 0%;
                        left: 0%;
                        content: '';
                        width: 30%;
                        height: 4px;
                        background-color: #429C48;
                    }
                }

                >div:nth-child(2) {
                    font-size: 1.3vw;
                    line-height: 2;
                }

                >img {
                    position: absolute;
                    width: 25%;
                    right: 0%;
                    bottom: 0%;
                }
            }
        }
    }

    .about-content-three {
        .content-three-1 {
            width: 90%;
            margin: 6% auto;
            padding-top: 4%;
            border-top: 2px solid #43A34A;
            border-bottom: 2px solid #43A34A;
            background: url(../assets/about/7.jpg) no-repeat center;
            background-size: cover;
            position: relative;

            >div:nth-child(1) {
                font-size: 3vw;
                padding-bottom: 3%;
                margin-bottom: 3%;
                position: relative;
                display: inline-block;
                background-image: linear-gradient(to right, #0D94A3, #47993F);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &::after {
                    position: absolute;
                    bottom: 0%;
                    left: 0%;
                    content: '';
                    width: 30%;
                    height: 4px;
                    background-color: #429C48;
                }
            }

            >div:nth-child(2) {
                font-size: 1.3vw;
                line-height: 2;
            }

            >div:nth-child(3) {
                display: flex;
                gap: 5%;
                align-items: center;
                width: 95%;
                margin: 8% auto 0% auto;
                transform: translateY(26%);

                >div {
                    flex: 1;
                    position: relative;
                    text-align: center;
                    aspect-ratio: 1/1;

                    >div {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 100;
                        color: white;
                        font-weight: bold;
                        font-size: 1vw;
                        white-space: nowrap;
                    }

                    >img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            >img {
                position: absolute;
                right: 0%;
                top: 50%;
                height: 65%;
                transform: translate(-50%, -68%);
            }
        }

        .content-three-2 {
            width: 90%;
            margin: 8% auto;
            box-sizing: border-box;

            >div:nth-child(1) {
                font-size: 3vw;
                padding-bottom: 3%;
                margin-bottom: 3%;
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                background-image: linear-gradient(to right, #0D94A3, #47993F);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &::after {
                    position: absolute;
                    bottom: 0%;
                    left: 0%;
                    content: '';
                    width: 30%;
                    height: 4px;
                    background-color: #429C48;
                }
            }

            >div:nth-child(2) {
                display: grid;
                width: 90%;
                margin: auto;
                grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
                gap: 5%;
                box-sizing: border-box;

                @media screen and (max-width: 500px) {
                    width: 100%;
                    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
                    gap: 2%;
                }

                >div {
                    font-size: 0.9vw;
                    height: auto;

                    >div:nth-child(1) {
                        position: relative;

                        >div:nth-child(2) {
                            position: absolute;
                            bottom: 0%;
                            color: white;
                            width: 100%;
                            text-align: center;
                            padding: 4% 0%;
                            background-color: rgba(14, 13, 13, 0.6);
                            font-size: 1.2vw;
                            font-weight: bold;
                        }
                    }

                    >div:nth-child(2) {
                        margin-top: 3%;
                        line-height: 1.5;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .content-three-3 {
            font-size: 1vw;
            background-color: #E5E5E5;

            >div:nth-child(1) {
                background: url(../assets/about/8.jpg) no-repeat center;
                background-size: cover;
                padding: 15vw 0vw 6vw 0vw;
                text-align: center;
                font-size: 3vw;
                font-weight: bold;
                color: white;
                letter-spacing: 3px;
                box-sizing: border-box;
            }

            >div:nth-child(2) {
                padding: 5% 10%;
                display: grid;
                box-sizing: border-box;
                grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
                column-gap: 10%;
                row-gap: 10%;

                >div {
                    >div:nth-child(1) {
                        background-image: linear-gradient(to right, #12929B, #449A42);
                        padding: 1% 4%;
                        color: white;
                        font-weight: bold;
                        font-size: 1.2vw;
                    }

                    >div:nth-child(2) {
                        margin: 4%;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 6%;
                            height: 2px;
                            background-color: black;
                            top: -0.6vw;
                            left: 0%;
                        }
                    }
                }
            }
        }
    }
}
</style>